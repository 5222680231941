<template>
  <div
    class="container mx-auto mt-2 mb-6 px-6 text-center flex flex-col md:flex-row md:justify-around"
  >
    <div class="flex flex-col md:w-1/2 text-center md:text-center">
      <h2
        class="font-sans text-2xl leading-normal mb-4 font-bold text-blueBrand mt-6"
      >
        Benefícios
      </h2>
      <ul>
        <li>Van com espaço, conforto e segurança</li>
        <li>Ar condicionado</li>
        <li>Motorista credenciado pela Prefeitura de São Paulo e Detran</li>
      </ul>
    </div>
    <div
      class="mt-6 md:mt-0 md:w-1/2 md:border-l-4 md:border-orangeBrand md:pl-4 max-sm:border-t-4 border-orangeBrand"
    >
      <h2
        class="font-sans text-2xl leading-normal mb-4 font-bold text-blueBrand mt-6"
      >
        Grade
      </h2>
      <div class="flex flex-col mb-9 pb-4">
        <ul>
          <li>Adelina Issa Ashcar - Manhã e Tarde</li>
          <li>Oscar Thompson - Período Integral</li>
          <li>Murtinho Nobre - Período Integral</li>
          <li>EMEI Alberto de Oliveira - Manhã e Tarde</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

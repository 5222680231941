<!-- Footer.vue -->
<template class="relative">
  <footer class="bg-blueBrand bottom-0 w-full">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
      <div class="md:flex md:justify-between">
        <div class="mt-0 md:mb-0">
          <a
            href="https://carlostransporte.com.br/"
            class="flex items-center max-sm:ml-1"
          >
            <img
              src="../assets/logo.png"
              class="h-20 me-3"
              alt="FlowBite Logo"
            />
            <span
              class="self-center text-2xl font-semibold whitespace-nowrap text-orangeBrand"
              >Carlos Transporte Escolar</span
            >
          </a>
        </div>
        <div
          class="mt-7 flex-col sm:flex-col sm:items-center sm:justify-between"
        >
          <span
            class="text-sm text-white sm:text-center dark:text-gray-400 max-sm:ml-7"
            >© 2024
            <a href="https://www.mprbiz.com.br/" class="hover:underline"
              >Mpr Biz</a
            >. All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<template>
  <div class="flex flex-col h-screen justify-between">
    <HeaderComp />
    <router-view />
    <FooterComp />
    <floatingWhatsappButton class="mb-0 fixed" />
  </div>
</template>

<script>
import FooterComp from "@/views/FooterComp.vue";
import HeaderComp from "@/views/HeaderComp.vue";
import floatingWhatsappButton from "./components/Pages/buttons/floatingWhatsappButton.vue";
export default {
  components: {
    HeaderComp,
    FooterComp,
    floatingWhatsappButton,
  },
};
</script>
<style></style>

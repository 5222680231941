<template>
  <div
    class="border-8 border-white w-full text-center text-white bg-orangeBrand"
  >
    <div class="mt-4">
      <h2
        class="pt-7 font-sans text-2xl leading-normal font-bold text-blueBrand"
      >
        Clique no botão abaixo e Entre em contato conosco
      </h2>
      <div>
        <wpp-buttons
          class="pt-12 pb-12 bg-red-400 px-4 py-5 flex justify-center items-center"
          label="Reserve sua vaga agora"
          to="https://whatsform.com/8hs4aa"
        ></wpp-buttons>
      </div>
    </div>
  </div>
</template>

<script>
import wppButtons from "@/components/Pages/buttons/wppButtons.vue";

export default {
  components: {
    wppButtons,
  },
};
</script>

<style></style>

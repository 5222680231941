<!-- Header.vue -->
<template>
  <nav class="bg-blueBrand border-gray-200 dark:bg-gray-900">
    <div
      class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
    >
      <a
        href="https://carlostransporte.com.br/"
        class="flex items-center space-x-3 rtl:space-x-reverse"
      >
        <img src="../assets/logo.png" class="h-20" alt="Flowbite Logo" />
        <span
          class="self-center text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold whitespace-nowrap text-orangeBrand"
        >
          Carlos Transporte Escolar
        </span>
      </a>
      <button
        data-collapse-toggle="navbar-default"
        type="button"
        class="text-white inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-default"
        aria-expanded="false"
        @click="toggleMenu"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      <div class="ml-auto hidden w-full md:block md:w-auto" id="navbar-default">
        <ul
          class="font-medium flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
        >
          <li
            class="block py-2 px-3 rounded text-orangeBrand"
            aria-current="page"
          >
            <router-link to="/" class="hover:text-white">Home</router-link>
          </li>

          <li
            class="block py-2 px-3 rounded text-orangeBrand dark:"
            aria-current="page"
          >
            <router-link to="/sobre" class="hover:text-white"
              >Sobre</router-link
            >
          </li>
          <li
            class="block py-2 px-3 rounded text-orangeBrand dark:"
            aria-current="page"
          >
            <router-link to="/grade" class="hover:text-white"
              >Grade</router-link
            >
          </li>
          <li
            class="block py-2 px-3 rounded text-orangeBrand"
            aria-current="page"
          >
            <router-link to="/contato" class="hover:text-white"
              >Contato</router-link
            >
          </li>
        </ul>
      </div>

      <div
        v-if="isMenuOpen"
        class="w-full md:block md:w-auto"
        id="navbar-default"
      >
        <ul
          class="font-medium flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
        >
          <li
            class="block py-2 px-3 rounded text-orangeBrand"
            aria-current="page"
          >
            <router-link to="/" class="hover:text-white">Home</router-link>
          </li>

          <li
            class="block py-2 px-3 rounded text-orangeBrand dark:"
            aria-current="page"
          >
            <router-link to="/sobre" class="hover:text-white"
              >Sobre</router-link
            >
          </li>
          <li
            class="block py-2 px-3 rounded text-orangeBrand dark:"
            aria-current="page"
          >
            <router-link to="/grade" class="hover:text-white"
              >Grade</router-link
            >
          </li>
          <li
            class="block py-2 px-3 rounded text-orangeBrand"
            aria-current="page"
          >
            <router-link to="/contato" class="hover:text-white"
              >Contato</router-link
            >
          </li>
        </ul>
      </div>
      <wppButtons
        class="block mx-auto mt-2 text-center md:inline md:mx-auto"
        label="WhatsApp"
        to="https://whatsform.com/8hs4aa"
      />
    </div>
  </nav>
</template>

<script>
import wppButtons from "@/components/Pages/buttons/wppButtons.vue";

export default {
  components: {
    wppButtons,
  },

  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

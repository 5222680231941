<template>
  <a target="_blanck" :href="to">
    <button
      class="bg-wppColor hover:bg-wppColorHover text-white font-bold py-2 px-4 hover:border-wppColorHover rounded flex gap-3"
    >
      {{ label }}
      <img
        class="h-7 rounded-lg w-50"
        src="../../../assets/whatsapp-svgrepo-com (1).svg"
      /></button
  ></a>
</template>

<script>
export default {
  name: "wppButtons",
  props: {
    to: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "string",
    },
  },
};
</script>

import { createRouter, createWebHistory } from "vue-router";

import HomeCompVue from "@/components/Pages/HomeComp.vue";
import SobreCompVue from "@/components/Pages/SobreComp.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeCompVue,
  },
  {
    path: "/sobre",
    name: "sobre",

    component: SobreCompVue,
  },
  {
    path: "/grade",
    name: "grade",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Pages/GradeComp.vue"
      ),
  },
  {
    path: "/contato",
    name: "contato",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Pages/ContatoComp.vue"
      ),
  },
  {
    path: "/formulario-aluno",
    name: "formulario",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Pages/formAluno.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

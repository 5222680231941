<template>
  <div class="">
    <section>
      <div
        id="services"
        class="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-white mt-0"
      >
        <div class="container xl:max-w-6xl mx-auto px-2 mb-0 max-sm:mt-[-60px]">
          <!-- Heading start -->
          <header class="text-center mx-auto mb-12 lg:px-20 mt-0">
            <h2
              class="font-sans text-2xl leading-normal font-bold text-blueBrand mt-10"
            >
              Sobre Carlos Transporte Escolar
            </h2>
          </header>
          <!-- End heading -->

          <!-- row -->
          <div
            class="flex flex-col sm:flex-row sm:-mx-28 xl:-mx-11 text-center mt-0"
          >
            <div
              class="px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              style="
                visibility: visible;
                animation-duration: 1s;
                animation-name: fadeInUp;
              "
            >
              <div
                class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2"
              >
                <div class="inline-block text-gray-900 mb-4">
                  <!-- icon -->
                  <img src="../../img/map-pin-alt-svgrepo-com (2).svg" alt="" />
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-orangeBrand"
                >
                  Regiões Atendidas
                </h3>
                <p class="text-blueBrand">
                  Cambuci, Vila Monumento, Aclimação e Liberdade
                </p>
              </div>
            </div>
            <div
              class="px-4 max-w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              style="
                visibility: visible;
                animation-duration: 1s;
                animation-name: fadeInUp;
              "
            >
              <!-- service block -->
              <div
                class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2"
              >
                <div class="inline-block text-gray-900 mb-4">
                  <!-- icon -->
                  <img src="../../img/van-svgrepo-com.svg" alt="" />
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-orangeBrand"
                >
                  Desde 1995
                </h3>
                <p class="text-blueBrand">
                  Mais de 29 anos transportando crianças para as escolas
                </p>
              </div>

              <!-- end service block -->
            </div>

            <div
              class="px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".1s"
              style="
                visibility: visible;
                animation-duration: 1s;
                animation-delay: 0.1s;
                animation-name: fadeInUp;
              "
            >
              <!-- service block -->
              <div
                class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2"
              >
                <div class="inline-block text-gray-900 mb-4">
                  <!-- icon -->
                  <img src="../../img/thumb-up-svgrepo-com.svg" alt="" />
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-orangeBrand"
                >
                  + de 1000 crianças
                </h3>
                <p class="text-blueBrand">
                  Já tranportamos diversas crianças com segurança e conforto
                </p>
              </div>
              <!-- end service block -->
            </div>

            <div
              class="px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              style="
                visibility: visible;
                animation-duration: 1s;
                animation-delay: 0.3s;
                animation-name: fadeInUp;
              "
            >
              <!-- service block -->
              <div
                class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2"
              >
                <div class="inline-block text-gray-900 mb-4">
                  <!-- icon -->
                  <img src="../../img/school-flag-svgrepo-com.svg" alt="" />
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-orangeBrand"
                >
                  7 escolas
                </h3>
                <p class="text-blueBrand">
                  Ao longo de nossa história, já atendemos 7 escolas
                </p>
              </div>
              <!-- end service block -->
            </div>
          </div>
          <!-- end row -->
        </div>
      </div>

      <!-- component -->
      <section
        class="lg:h-[200px] xl:h-[250px] border-8 border-white px-6 py-4 mx-auto-full bg-blueBrand border-"
      >
        <h2
          class="mt-8 text-center font-sans text-2xl leading-normal mb-8 font-bold text-white"
        >
          Escolas atendidas
        </h2>
        <div class="grid gap-6 mb-8 md:grid-cols-2 lg:grid-cols-4">
          <!-- Card 1 -->
          <div
            class="flex-row items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm dark:bg-gray-800"
          >
            <div>
              <p class="text-center mb-2 text-xl font-medium text-orangeBrand">
                Adelina Issa Ashcar
              </p>
            </div>
          </div>
          <!-- Card 4 -->
          <div
            class="flex-row items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm dark:bg-gray-800"
          >
            <div>
              <p class="text-center mb-2 text-xl font-medium text-orangeBrand">
                Oscar Thompson
              </p>
            </div>
          </div>
          <!-- Card 2 -->
          <div
            class="flex-row items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm dark:bg-gray-800"
          >
            <div>
              <p class="text-center mb-2 text-xl font-medium text-orangeBrand">
                Murtinho Nobre
              </p>
            </div>
          </div>
          <!-- Card 3 -->
          <div
            class="flex-row items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm dark:bg-gray-800"
          >
            <div>
              <p class="text-center mb-2 text-xl font-medium text-orangeBrand">
                EMEI Alberto de Oliveira
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

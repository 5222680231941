<template>
  <section class="max-sm:h-[200px]">
    <div class="">
      <div class="w-full h-full bg-orangeBrand">
        <img
          src="../../assets/teste.png
        "
          alt=""
        />
      </div>
    </div>
  </section>
  <SobreComp></SobreComp>
  <GradeComp></GradeComp>
  <ContatoComp></ContatoComp>
</template>

<script>
import ContatoComp from "./ContatoComp.vue";
import GradeComp from "./GradeComp.vue";
import SobreComp from "./SobreComp.vue";
export default {
  components: { SobreComp, GradeComp, ContatoComp },
};
</script>

<style></style>

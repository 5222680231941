<template>
  <div class="">
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
    />

    <a
      href="https://whatsform.com/8hs4aa"
      class="bg-wppColor hover:bg-wppColorHover fixed w-[60px] h-[60px] bottom-6 right-6 text-white rounded-full text-center text-3xl z-1000"
      style="box-shadow: 1px 1px 2px #888"
      target="_blank"
    >
      <i style="margin-top: 16px" class="fa fa-whatsapp"></i>
    </a>
  </div>
</template>

<script>
export default {};
</script>
